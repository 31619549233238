<template>
  <div>
    <b-form>
      <validation-observer ref="rulesMoveCard">
        <!-- always show -->
        <div>
          <b-row>
            <b-col cols="8">
              <b-form-group
                label="Kanban"
                label-for="column"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-select
                    id="column"
                    v-model="newCard.role"
                    :options="roles"
                    :state="errors.length > 0 ? false:null"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row v-if="newCard.role">
            <b-col cols="8">
              <b-form-group
                label="Coluna"
                label-for="role"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-select
                    id="role"
                    v-model="newCard.column"
                    :options="optionsColumn"
                    :state="errors.length > 0 ? false:null"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row v-if="newCard.role">
            <b-col cols="8">
              <b-form-group
                label="Consultor"
                label-for="account_manager_id"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-select
                    id="account_manager_id"
                    v-model="newCard.account_manager_id"
                    :options="optionsManager"
                    :state="errors.length > 0 ? false:null"
                  />
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </div>

        <div v-if="newCard.account_manager_id">
          <div v-if="newCard.role === 'relationship' && newCard.column === 'schedule'" />

          <div v-if="(newCard.role === 'relationship' && newCard.column === 'onboarding') || (newCard.role === 'mentoring' && newCard.column === 'meet')">
            <b-row>
              <b-col cols="8">
                <b-form-group
                  label="Data do Tour"
                  label-for="schedule_at"
                >
                  <flat-pickr
                    id="schedule_at"
                    ref="scheduleAt"
                    v-model="newCard.schedule_at"
                    class="form-control"
                    :config="{
                      allowInput: false,
                      position: 'auto center',
                      enableTime: true,
                      dateFormat: 'Y-m-d H:i:S',
                      altFormat: 'd/m \\à\\s H:i',
                      altInput: true,
                      time_24hr: true,
                      locale: Portuguese,
                    }"
                  />

                  <!-- a validação é fora do datepicker, pois o validation-provider impede -->
                  <!-- o primeiro clique de funcionar dentro do date-picker -->

                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="8">
                <b-form-group
                  label="Tipo de agendamento"
                  label-for="schedule_type"
                >
                  <b-form-input
                    v-model="newCardFixed.relationship.onboarding.schedule_type"
                    :readonly="true"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </div> <!-- column === 'onboarding' -->

          <div v-if="newCard.role === 'relationship' && newCard.column === 'follow_up'">
            <b-row>
              <b-col cols="8">
                <b-form-group
                  label="Tentativas de contato"
                  label-for="relationship_follow_up_tries"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required|integer"
                  >
                    <b-form-select
                      id="relationship_follow_up_tries"
                      v-model="newCard.relationship_follow_up_tries"
                      :options="contactTries"
                      :state="errors.length > 0 ? false:null"
                    />
                    <span
                      v-if="errors"
                      class="text-danger"
                    >{{ errors[0] }}</span>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </div> <!-- column == 'follow_up' -->

          <div v-if="newCard.role === 'success' && newCard.column === 'new_subscriber'">
            <b-row>
              <b-col cols="8">
                <b-form-group
                  label="Tentativas de contato"
                  label-for="success_new_subscriber_tries"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required|integer"
                  >
                    <b-form-select
                      id="success_new_subscriber_tries"
                      v-model="newCard.success_new_subscriber_tries"
                      :options="contactTries"
                      :state="errors.length > 0 ? false:null"
                    />
                    <span
                      v-if="errors"
                      class="text-danger"
                    >{{ errors[0] }}</span>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </div> <!-- column == 'new_subscriber' -->

          <div v-if="newCard.role === 'success' && newCard.column === 'follow_up'">
            <b-row>
              <b-col cols="8">
                <b-form-group
                  label="Contato feito"
                  label-for="success_new_subscriber_contact_made"
                >
                  <b-form-checkbox
                    id="favorites"
                    v-model="newCard.success_new_subscriber_contact_made"
                    class="custom-control-primary"
                    name="check-button"
                    switch
                    inline
                  >
                    <span class="switch-icon-left">
                      <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                      <feather-icon icon="XIcon" />
                    </span>
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
          </div> <!-- column == 'new_subscriber' -->

          <div v-if="newCard.role === 'success' && newCard.column === 'future_schedule'">
            <b-row>
              <b-col cols="8">
                <b-form-group
                  label="Data do próximo contato"
                  label-for="success_new_contact_at"
                >
                  <flat-pickr
                    id="success_new_contact_at"
                    ref="scheduleAt"
                    v-model="newCard.success_new_contact_at"
                    class="form-control"
                    :config="{
                      allowInput:false,
                      position: 'auto center',
                      enableTime: true,
                      dateFormat: 'Y-m-d H:i:S',
                      altFormat: 'd/m \\à\\s H:i',
                      altInput: true,
                      time_24hr: true,
                    }"
                  />

                  <!-- a validação é fora do datepicker, pois o validation-provider impede -->
                  <!-- o primeiro clique de funcionar dentro do date-picker -->
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                  >
                    <b-form-input
                      v-model="newCard.success_new_contact_at"
                      class="d-none"
                    />
                    <span
                      v-if="errors"
                      class="text-danger"
                    >{{ errors[0] }}</span>
                  </validation-provider>

                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="8">
                <b-form-group
                  label="Omitir até"
                  label-for="hide_until"
                >
                  <b-form-input
                    v-model="newCardFixed.success.future_schedule.hide_until"
                    :readonly="true"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </div> <!-- column == 'future_schedule' -->

          <div v-if="newCard.role === 'sales' && newCard.column === 'new_lead'">
            <b-row>
              <b-col cols="8">
                <b-form-group
                  label="Consultor de sucesso"
                  label-for="success_manager_id"
                >
                  <b-form-input
                    v-model="newCardFixed.sales.new_lead.success_manager_id"
                    :readonly="true"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="8">
                <b-form-group
                  label="Consultor de sucesso atribuído(para null) em"
                  label-for="success_manager_at"
                >
                  <b-form-input
                    v-model="newCardFixed.success_manager_at"
                    :readonly="true"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </div> <!-- column == 'new_lead' -->

          <div>
            <b-row>
              <b-col cols="8">
                <b-form-group
                  label="Descrição"
                  label-for="description"
                >
                  <b-form-textarea
                    v-model="newCard.description"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </div>

        </div> <!-- with account_manager_id -->
      </validation-observer>
    </b-form>

    <b-row>
      <b-col class="text-right">
        <b-button @click="submit">
          Salvar
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BButton,
  BFormSelect,
  BFormInput,
  BFormTextarea,
  BFormCheckbox,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import KanbanService from '@/services/kanbanService'
import UserService from '@/services/userService'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { Portuguese } from 'flatpickr/dist/l10n/pt'
import moment from 'moment'
import toast from '../../../mixins/toast'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BButton,
    BFormSelect,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    flatPickr,
  },
  mixins: [
    toast,
  ],
  props: {
    data: {
      type: Object,
      default: null,
    },
    users: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      kanbanService: null,
      userService: null,
      Portuguese,
      formErrors: {},
      isLoading: false,
      userData: {},
      managers: [],
      newCardFixed: {
        service_qualification: null,
        relationship: {
          onboarding: {
            schedule_type: 'manual',
          },
        },
        success: {
          future_schedule: {
            hide_until: 'null',
          },
        },
        sales: {
          new_lead: {
            success_manager_id: 'null',
            success_manager_at: null,
          },
        },
      },
      newCard: {
        schedule_at: '',
        relationship_follow_up_tries: '',
        relationship_following_at: '',
        relationship_transition_at: '',
        success_new_subscriber_tries: '',
        success_new_subscriber_at: '',
        success_new_subscriber_contact_made: '',
        success_follow_up_at: '',
        success_new_contact_at: '',
        sales_new_lead_at: '',
        description: '',
        account_manager_id: '',
      },
      contactTries: [
        { value: 0, text: '0' },
        { value: 1, text: '1' },
        { value: 2, text: '2' },
        { value: 3, text: '3' },
      ],
      roles: [
        { value: 'service_qualification', text: 'Atendimento e Qualificação' },
        { value: 'relationship', text: 'Canal VIP - Onboarding' },
        { value: 'success', text: 'Canal VIP - Assinantes' },
        { value: 'mentoring', text: 'Mentoria' },
        { value: 'sales', text: 'Vendas' },
        { value: 'cerc', text: 'Cerc' },
      ],
      columns: {
        service_qualification: {
          qualification: 'Qualificação',
          double: 'Duplos',
          active_review: 'Ajustes Ativos',
          finished: 'Finalizado',
        },
        relationship: {
          schedule: 'Qualificados Tour',
          onboarding: 'Tours Agendados',
          follow_up: 'Follow Onboarding',
          transition: 'Transição',
          finished: 'Finalizado',
        },
        success: {
          new_subscriber: 'Apresentação GC',
          follow_up: 'Follow GC',
          future_schedule: 'Agendamentos Futuros',
        },
        sales: {
          new_lead: 'Novos Leads',
          finished: 'Finalizado',
        },
        mentoring: {
          qualified: 'Qualificados',
          meet: 'Reunião Alinhamento',
          follow: 'Follow Alinhamento',
          qbr: 'QBR - Follow Review',
          finished: 'Finalizado',
        },
        cerc: {
          new_lead: 'Novos Leads',
          finished: 'Finalizado',
        },
      },
    }
  },
  computed: {
    optionsManager() {
      return this.managers.filter(f => f.permission_type === this.newCard.role
          || (this.newCard.role === 'success' && ['success', 'relationship', 'service_qualification', 'mentoring'].includes(f.permission_type))
          || (this.newCard.role === 'relationship' && ['success', 'relationship', 'service_qualification'].includes(f.permission_type))
          || (this.newCard.role === 'cerc' && f.permission_type !== 'root')).map(m => ({
        value: m.id,
        text: `${this.$t(`users.permission_type.${m.permission_type}`)} - ${m.name}`,
      })).sort((a, b) => (a.text.localeCompare(b.text)))
    },
    optionsColumn() {
      return this.columns[this.newCard.role]
    },
  },
  async created() {
    this.kanbanService = new KanbanService()
    this.userService = new UserService()
    const userDataString = await localStorage.getItem('userData')
    this.userData = await JSON.parse(userDataString)
    await this.userService.getAndStoreManagers().then(response => {
      this.managers = response
    })

    this.newCardFixed.success_manager_at = moment().format('YYYY-MM-DD H:m:s')
    this.newCard = JSON.parse(JSON.stringify(this.data))
  },
  methods: {
    submit() {
      this.$refs.rulesMoveCard.validate().then(success => {
        if (success) {
          const postData = {}

          Object.entries(this.data).forEach(val => {
            const index = val[0]
            const value = val[1]

            if (['user', 'user_settings'].includes(index)) {
              return
            }

            if ((this.newCard[index] || this.newCard[index] === false) && this.newCard[index] !== value) {
              postData[index] = this.newCard[index]
            } else if (index === 'column' || index === 'role') {
              postData[index] = this.newCard[index]
            } else if (
              (
                this.newCardFixed[this.newCard.role]
                && this.newCardFixed[this.newCard.role][this.newCard.column]
                && this.newCardFixed[this.newCard.role][this.newCard.column][index]
              )
              && (
                this.newCardFixed[this.newCard.role][this.newCard.column][index] === false
                || this.newCardFixed[this.newCard.role][this.newCard.column][index] !== value
              )
            ) {
              postData[index] = this.newCardFixed[this.newCard.role][this.newCard.column][index]
            }
          })

          if (Object.entries(postData).length) {
            this.kanbanService.moveCard(this.data.user_id, postData).then(response => {
              if (response.status === 200) {
                this.toastSuccess('Card movido com sucesso!')
                this.$emit('reload')
              } else {
                this.toastDanger('Erro ao mover o card.')
              }
            }).catch(() => {
              this.toastDanger('Erro ao mover o card.')
            })
          } else {
            this.toastDanger('Nenhuma alteração para ser feita.')
          }
        } else {
          this.toastDanger('Resolva todos os erros de validação.')
        }
      })
    },
  },
}
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
    @import '@core/scss/vue/pages/page-misc.scss';

  .numInput{
    pointer-events: none;
  }
</style>
